import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import * as S from '../styles/pages/aboutUsStyle.js'

export default function AboutUs({ data }) {
  return (
    <Layout>
      <S.AboutUsSection>
        <S.AboutUsContainer>
          <S.AboutUsMainContainer>
            <S.AboutUsHeader>O Salonie</S.AboutUsHeader>
            <S.AboutUsTextContainer>
              <S.AboutUsText>
                Salon Kosmetyki Profesjonalnej „Evita” został założony w roku
                2010 przez Ewę Plekaniec, kosmetyczkę z 28-letnim doświadczeniem
                w branży kosmetologicznej. Obecnie za jego sukcesem stoi pięć
                wykwalifikowanych pasjonatek. Jest to miejsce wyróżniające się
                indywidualnym, ciepłym podejściem do klienta, wysokim stopniem
                profesjonalizmu i niegasnącą pasją. Klienci cenią sobie naszą
                opiekę, darzą nas zaufaniem i chętnie do nas wracają, ponieważ
                ich satysfakcja i zadowolenie z rozwiązanych problemów jest
                naszym priorytetem.
              </S.AboutUsText>
              <S.AboutUsText>
                Od lat nieprzerwanie doskonalimy swoje umiejętności, czego
                potwierdzeniem są uzyskane na szkoleniach certyfikaty. Szkolenia
                te uprawniają nas do wykonywania skomplikowanych zabiegów i są
                gwarantem naszego profesjonalizmu.
              </S.AboutUsText>
              <S.AboutUsText>
                Nasz Salon ma swoją placówkę w centrum Żywca, miasta położonego
                w Beskidzie Żywieckim, miejscu malowniczym i inspirującym, w
                którym natura zdecydowanie odgrywa najważniejszą rolę. Pierwszą
                lokalizacją była ul. Jana, obecnie mamy dwa salony - na ul.
                Piłsudskiego oraz na ul. Kościuszki.
              </S.AboutUsText>
            </S.AboutUsTextContainer>
          </S.AboutUsMainContainer>
          <S.AboutUsImgContainer>
            <S.AboutUsImg
              fluid={data.heroImg.fluid}
              alt="Salon kosmetyki profesjonalnej Evita"
            />
          </S.AboutUsImgContainer>
        </S.AboutUsContainer>
      </S.AboutUsSection>
      <S.OurValuesSection>
        <S.OurValuesContainer>
          <S.OurValuesHeader>NASZE WARTOŚCI</S.OurValuesHeader>
          <S.ValueContainer>
            <S.ValueTitle>EKSPERCKO</S.ValueTitle>
            <S.ValueText>
              Każdy zabieg poprzedzony jest szczegółowym wywiadem i poznaniem
              oczekiwań naszych klientów. Terapie dobierane są indywidualnie do
              potrzeb, a serie zabiegów realizowane konsekwentnie, by uzyskać
              satysfakcjonujący efekt i pozbyć się niechcianych problemów.
              Ponadto, dobieramy spersonalizowaną pielęgnację domową, doradzamy,
              jak korzystać z kosmetyków oferowanych w naszym gabinecie i zawsze
              pozostajemy w kontakcie z naszymi klientami, by mieć pewność, że
              są w stu procentach zadowoleni z efektów naszej ciężkiej pracy.
              Jesteśmy profesjonalistkami w swojej dziedzinie, ciągle się
              rozwijamy, inwestując w nowoczesny sprzęt, szkolenia, linie
              kosmetyków, czytając literaturę fachową. Ekspercko - gwarancję
              takiego podejścia otrzymujesz po przekroczeniu naszego progu.
            </S.ValueText>
          </S.ValueContainer>
          <S.ValueContainer>
            <S.ValueTitle>EKSKLUZYWNIE</S.ValueTitle>
            <S.ValueText>
              W ofercie posiadamy wiodące marki kosmetyczne z najwyższej półki.
              Każdy klient może liczyć na filiżankę wyśmienitej kawy lub herbaty
              i kompleksową opiekę od powitania, poprzez wywiad i profesjonalny
              zabieg, aż po dobranie pielęgnacji domowej i troskę po opuszczeniu
              naszego gabinetu. Cenimy i szanujemy naszych klientów, dlatego z
              najwyższą starannością dbamy o przestrzeganie najsurowszych
              standardów sanitarnych w gabinecie oraz jego przytulny wystrój.
              Mamy ręcznie robione dekoracje i świece, bo wiemy, że luksus tkwi
              w najdrobniejszych szczegółach.
            </S.ValueText>
          </S.ValueContainer>
          <S.ValueContainer>
            <S.ValueTitle>EKOLOGICZNIE</S.ValueTitle>
            <S.ValueText>
              Niezwykle leży nam na sercu dobro naszej planety, która jest dla
              nas niewyczerpanym źródłem inspiracji i która tak wiele nam daje.
              Dlatego ze wszystkich sił staramy się stanąć do walki ze zmianami
              klimatycznymi i wspólnie zadbać o to, byśmy jak najdłużej mogli
              cieszyć się dobrodziejstwami Matki Ziemi. Wprowadzamy linie
              wegańskich kosmetyków - Ilcsi, całkowicie naturalnych i
              nietestowanych na zwierzętach i zdecydowanie planujemy poszerzanie
              naszego asortymentu w tym zakresie. Segregujemy odpady,
              minimalizujemy ilość opakowań i na każdym kroku staramy się, by
              ekologia była nie tylko trendem, ale filozofią życiową, świadomym
              wyborem i zmianą na lepsze.
            </S.ValueText>
          </S.ValueContainer>
        </S.OurValuesContainer>
      </S.OurValuesSection>
    </Layout>
  )
}

export const query = graphql`
  query AboutUsQuery {
    heroImg: imageSharp(
      fluid: {
        originalName: { eq: "salon_kosmetyki_profesjonalnej_evita_26.jpg" }
      }
    ) {
      fluid(maxWidth: 970, quality: 100) {
        src
      }
    }
  }
`
