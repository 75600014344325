import styled from 'styled-components'
import * as G from '../globalStyle'
import Img from 'gatsby-image'
//AboutUs Section

export const AboutUsSection = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-bottom: 150px;
    padding-top: 115px;
  }
`

export const OurValuesSection = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-bottom: 150px;
    padding-top: 20px;
  }
`

export const AboutUsContainer = styled(G.Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${props => props.theme.size.large}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const OurValuesContainer = styled(G.Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: url('../../hands.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const AboutUsHeader = styled(G.MainHeader)`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`

export const OurValuesHeader = styled(G.SubSubHeader)`
  width: 100%;
  text-align: center;
`

export const AboutUsText = styled(G.MainText)`
  margin-top: 20px;
  max-width: 550px;
  text-align: center;

  @media (min-width: ${props => props.theme.size.large}) {
    max-width: unset;
    text-align: justify;
  }
`

export const AboutUsTextContainer = styled.div`
  margin-top: 12px;
`

export const AboutUsMainContainer = styled.div`
  width: 100%;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: ${props => props.theme.size.small}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    max-width: unset;
    padding-right: 20px;
    width: 50%;
  }
`

export const AboutUsImg = styled(Img)`
  width: 100%;
  object-fit: cover;
  margin-top: 50px;
  overflow: visible !important;
  position: static !important;

  img {
    position: static !important;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    margin-top: 0;
  }
`

export const AboutUsImgContainer = styled.div`
  width: 100%;
  max-width: 735px;

  @media (min-width: ${props => props.theme.size.large}) {
    width: 50%;
    max-width: unset;
    padding-left: 20px;
    flex: 1;
  }
`

export const ValueContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;

  &:nth-child(2n + 3) {
    align-self: flex-end;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: 55%;
    margin-bottom: 80px;
  }
`

export const ValueTitle = styled.h4`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 30px;
`

export const ValueText = styled(G.MainText)`
  text-align: justify;
`
